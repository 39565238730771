import { useUiState } from '@/parts/loading/hook'
import Link from 'next/link'
import { useEffect } from 'react'

export const config = {
  experimental: {
    runtime: 'edge',
  },
}

export default function Custom404() {
  const { setUiToReady } = useUiState()

  useEffect(setUiToReady)

  return (
    <div className="h-screen flex flex-col justify-center items-center gap-5">
      <div>
        <h1 className="font-bold text-5xl">Not Found</h1>
        <span>Sorry, that page does not exist.</span>
      </div>
      <Link
        className="rounded bg-white/50 p-4 w-64 flex justify-between items-center hover:bg-white transition-colors"
        href="/"
      >
        <span className="text-xl">Go home</span>
        <span className="text-3xl">&#8592;</span>
      </Link>
    </div>
  )
}
